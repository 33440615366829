import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';





const ThePatricks = () => {

    const [clicked, setClicked] = React.useState(true);
    const [clickedRenderExter, setClickedRenderExter] = React.useState(false);
    const [clickedRenderInter, setClickedRenderInter] = React.useState(false);
    const [clickedRenderMop, setClickedRenderMop] = React.useState(false);
    const [clickedRenderPlanDesc, setClickedRenderPlanDesc] = React.useState(false);
    const [clickedRenderPlanOther, setClickedRenderPlanOther] = React.useState(false);
    const [clickedRenderPlanMob, setClickedRenderPlanMob] = React.useState(false);
    const [clickedRenderPrezentMob, setClickedRenderPrezentMob] = React.useState(false);
    const [clickedRenderPrez, setClickedRenderPrez] = React.useState(false);
    const [clickedRenderPrezAngl, setClickedRenderPrezAngl] = React.useState(false);
    const [clickedRenderPentPrez, setClickedRenderPentPrez] = React.useState(false);
    const [clickedRenderPentRender, setClickedRenderPentRender] = React.useState(false);
    const [clickedRenderPentRenderTerr, setClickedRenderPentRenderTerr] = React.useState(false);
    const [clickedRenderPentPlan, setClickedRenderPentPlan] = React.useState(false);
    const [clickedRenderPentPlanMob, setClickedRenderPentPlanMob] = React.useState(false);

    // clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false

    let tg = window.Telegram.WebApp;
    
    
    
    tg.MainButton.text = "Скачать"; //изменяем текст кнопки 
    tg.MainButton.setText("Скачать"); //изменяем текст кнопки иначе
    tg.MainButton.textColor = "#000000"; //изменяем цвет текста кнопки
    tg.MainButton.color = "#CED8DD"; //изменяем цвет бэкграунда кнопки
    tg.MainButton.setParams({"color": "#CED8DD"}); 
    



    const handleChangeChekcedRenderExter = (e) => {
        setClickedRenderExter(e.target.checked)
        
        if(e.target.checked == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedRenderInter = (e) => {
        setClickedRenderInter(e.target.checked)
        
        if(clickedRenderExter == false && e.target.checked == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedRenderMop = (e) => {
        setClickedRenderMop(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && e.target.checked == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedRenderPlanDesc = (e) => {
        setClickedRenderPlanDesc(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && e.target.checked == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedRenderPlanOther = (e) => {
        setClickedRenderPlanOther(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && e.target.checked == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedRenderPlanMob = (e) => {
        setClickedRenderPlanMob(e.target.checked)
       
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && e.target.checked == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPrez = (e) => {
        setClickedRenderPrez(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && e.target.checked == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPrezAngl = (e) => {
        setClickedRenderPrezAngl(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && e.target.checked == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPentPrez = (e) => {
        setClickedRenderPentPrez(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && e.target.checked == false && clickedRenderPentRender == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPentRender = (e) => {
        setClickedRenderPentRender(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && e.target.checked == false && clickedRenderPentRenderTerr == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }
    
    const handleChangeChekcedPentRenderTerr = (e) => {
        setClickedRenderPentRenderTerr(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false && e.target.checked == false && clickedRenderPentPlan == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPentPlan = (e) => {
        setClickedRenderPentPlan(e.target.checked)
        
        if(clickedRenderExter == false && clickedRenderInter == false && clickedRenderMop == false && clickedRenderPlanDesc == false && clickedRenderPlanOther == false && clickedRenderPlanMob == false && clickedRenderPrez == false && clickedRenderPrezAngl == false && clickedRenderPentPrez == false && clickedRenderPentRender == false  && clickedRenderPentRenderTerr == false && e.target.checked == false && clickedRenderPentPlanMob == false && clickedRenderPrezentMob == false ){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show()
        }
    }

    const handleChangeChekcedPentPlanMob = (e) => {
        setClickedRenderPentPlanMob(e.target.checked)
        
        if(clickedRenderExter == false && 
            clickedRenderInter == false && 
            clickedRenderMop == false && 
            clickedRenderPlanDesc == false && 
            clickedRenderPlanOther == false && 
            clickedRenderPlanMob == false && 
            clickedRenderPrez == false && 
            clickedRenderPrezAngl == false && 
            clickedRenderPentPrez == false && 
            clickedRenderPentRender == false  && 
            clickedRenderPentRenderTerr == false && 
            clickedRenderPentPlan == false && 
            clickedRenderPrezentMob == false &&
            e.target.checked == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show(e.target.checked)
        }
    }


    const handleChangeChekcedRenderPrezentMob = (e) => {
        setClickedRenderPrezentMob(e.target.checked)
        if(clickedRenderExter == false && 
            clickedRenderInter == false && 
            clickedRenderMop == false && 
            clickedRenderPlanDesc == false && 
            clickedRenderPlanOther == false && 
            clickedRenderPlanMob == false && 
            clickedRenderPrez == false && 
            clickedRenderPrezAngl == false && 
            clickedRenderPentPrez == false && 
            clickedRenderPentRender == false  && 
            clickedRenderPentRenderTerr == false && 
            clickedRenderPentPlan == false && 
            clickedRenderPentPlanMob == false  && 
            e.target.checked == false){
            tg.MainButton.hide()
        }
        else{
            tg.MainButton.show(e.target.checked)
        }
    }

   


    const sendDataToTelegram = () => {
        tg.sendData(`{"clickedRenderExter": "${clickedRenderExter}", 
        "clickedRenderInter":  "${clickedRenderInter}", 
        "clickedRenderMop": "${clickedRenderMop}", 
        "clickedRenderPlanDesc":  "${clickedRenderPlanDesc}", 
        "clickedRenderPlanMob":  "${clickedRenderPlanMob}", 
        "clickedRenderPrez": "${clickedRenderPrez}", 
        "clickedRenderPrezAngl":  "${clickedRenderPrezAngl}", 
        "clickedRenderPentPrez":  "${clickedRenderPentPrez}",
        "clickedRenderPentRender":  "${clickedRenderPentRender}", 
        "clickedRenderPentRenderTerr":  "${clickedRenderPentRenderTerr}",
        "clickedRenderPentPlan":  "${clickedRenderPentPlan}",
        "clickedRenderPentPlanMob":  "${clickedRenderPentPlanMob}",
        "clickedRenderPrezentMob": "${clickedRenderPrezentMob}"}`);
        
      }
    


 
  
      React.useEffect(() => {
        tg.onEvent('mainButtonClicked', sendDataToTelegram)
        return () => {
          tg.offEvent('mainButtonClicked', sendDataToTelegram)
        }
      }, [sendDataToTelegram])


    useEffect(() => {
        window.scrollTo(0, 0)
        tg.MainButton.hide()
      }, [])
  return (
    <Grid >
    <Box sx={{p:3}} >
    <Grid container spacing={3} direction="column" alignItems="center" style={{justifyContent:"center"}}>
        <Box sx={{pt:3}} >
            <img src='../../img/Logo black.png' alt="Logo" width="150" />
        </Box>        
        <Box sx={{pt:3}} >
             <img  src='../../img/SP_4_21.jpg' width="380" />
        </Box>
        
    </Grid>   
    </Box>
    <Box sx={{p:3}} >
    <Grid container  direction="column" alignItems="left" style={{justifyContent:"center"}}>

    <Typography sx={{mb:1}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Презентации о проекте</Typography>
        <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPrez} onChange={handleChangeChekcedPrez}/>} label="Имиджевый буклет" />
        {/* <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPrezAngl} onChange={handleChangeChekcedPrezAngl}/>} label="English version" /> */}

    <Typography sx={{mb:1, mt:3}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Фото и рендеры</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox color="default" checked={clickedRenderExter} onChange={handleChangeChekcedRenderExter} />} label="Фасад" />
            
            <FormControlLabel control={<Checkbox color="default" checked={clickedRenderMop} onChange={handleChangeChekcedRenderMop}/>} label="Места общественного пользования" />
        </FormGroup>
    <Typography sx={{mb:1, mt:3}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Апартаменты</Typography>
    <FormControlLabel control={<Checkbox color="default" checked={clickedRenderInter} onChange={handleChangeChekcedRenderInter}/>} label="Интерьеры" />
        {/* <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPlanDesc} onChange={handleChangeChekcedRenderPlanDesc}/>} label="Полная версия" /> */}
    <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPlanMob} onChange={handleChangeChekcedRenderPlanMob}/>} label="Планировочные решения" />
    <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPrezentMob} onChange={handleChangeChekcedRenderPrezentMob}/>} label="Презентации" />

    {/*<Typography sx={{mb:1, mt:3}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Пентхаус</Typography>
    <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPentPrez} onChange={handleChangeChekcedPentPrez}/>} label="Презентация" />
        <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPentRender} onChange={handleChangeChekcedPentRender}/>} label="Интерьеры" />
        <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPentRenderTerr} onChange={handleChangeChekcedPentRenderTerr}/>} label="Террасы" />
        
         <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPentPlan} onChange={handleChangeChekcedPentPlan}/>} label="Планировочное решение. Полная версия." /> 
        <FormControlLabel control={<Checkbox color="default" checked={clickedRenderPentPlanMob} onChange={handleChangeChekcedPentPlanMob}/>} label="Планировочное решение" />   */}    
    </Grid>
</Box>
  </Grid>
  );
};
 
export default ThePatricks;