import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const MalayaBronnaya = () => {
  const [clickedRenderKv220Inter, setClickedRenderKv220Inter] = React.useState(false);
  const [clickedRenderKv220Prez, setClickedRenderKv220Prez] = React.useState(false);
  const [clickedRenderKv220Plan, setClickedRenderKv220Plan] = React.useState(false);
  const [clickedRenderPentPrezMB15, setClickedRenderPentPrez] = React.useState(false);
  const [clickedRenderPentInter, setClickedRenderPentInter] = React.useState(false);
  const [clickedRenderPentPlanMB15, setClickedRenderPentPlan] = React.useState(false);
  const [clickedRenderProjectMB15Prez, setClickedRenderProjectMB15Prez] = React.useState(false);
  const [clickedRenderProjectMB15Fasad, setClickedRenderProjectMB15Fasad] = React.useState(false);
  const [clickedRenderProjectMB15Mop, setClickedRenderProjectMB15Mop] = React.useState(false);
  const [clickedRenderProjectMB15Parking, setClickedRenderProjectMB15Parking] = React.useState(false);




  let tg = window.Telegram.WebApp;
    
    
    
  tg.MainButton.text = "Скачать"; //изменяем текст кнопки 
  tg.MainButton.setText("Скачать"); //изменяем текст кнопки иначе
  tg.MainButton.textColor = "#000000"; //изменяем цвет текста кнопки
  tg.MainButton.color = "#CED8DD"; //изменяем цвет бэкграунда кнопки
  tg.MainButton.setParams({"color": "#CED8DD"}); 
  

  const handleChangeChekcedRenderKv220Inter = (e) => {
    setClickedRenderKv220Inter(e.target.checked)
    
    if(e.target.checked == false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&
    clickedRenderPentPlanMB15 === false &&
    clickedRenderProjectMB15Prez === false &&
    clickedRenderProjectMB15Fasad === false &&
    clickedRenderProjectMB15Mop === false &&
    clickedRenderProjectMB15Parking === false      
      ){
        tg.MainButton.hide()
    }
    else{
        tg.MainButton.show()
    }
}


const handleChangeChekcedRenderKv220Prez = (e) => {
  setClickedRenderKv220Prez(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&

clickedRenderKv220Plan === false &&
clickedRenderPentPrezMB15 === false &&
clickedRenderPentInter === false &&
clickedRenderPentPlanMB15 === false &&
clickedRenderProjectMB15Prez === false &&
clickedRenderProjectMB15Fasad === false &&
clickedRenderProjectMB15Mop === false &&
clickedRenderProjectMB15Parking === false){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}

const handleChangeChekcedRenderKv220Plan = (e) => {
  setClickedRenderKv220Plan(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
clickedRenderKv220Prez === false &&
clickedRenderPentPrezMB15 === false &&
clickedRenderPentInter === false &&
clickedRenderPentPlanMB15 === false &&
clickedRenderProjectMB15Prez === false &&
clickedRenderProjectMB15Fasad === false &&
clickedRenderProjectMB15Mop === false &&
clickedRenderProjectMB15Parking === false){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}

const handleChangeChekcedRenderPentPlan = (e) => {
  setClickedRenderPentPlan(e.target.checked)
  
  if(clickedRenderKv220Inter === false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&

    clickedRenderProjectMB15Prez === false &&
    clickedRenderProjectMB15Fasad === false &&
    clickedRenderProjectMB15Mop === false &&
    clickedRenderProjectMB15Parking === false &&
    e.target.checked == false){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}

const handleChangeChekcedRenderPentInter = (e) => {
  setClickedRenderPentInter(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
clickedRenderKv220Prez === false &&
clickedRenderKv220Plan === false &&
clickedRenderPentPrezMB15 === false &&

clickedRenderPentPlanMB15 === false &&
clickedRenderProjectMB15Prez === false &&
clickedRenderProjectMB15Fasad === false &&
clickedRenderProjectMB15Mop === false &&
clickedRenderProjectMB15Parking === false
){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}


const handleChangeChekcedRenderPentPrez = (e) => {
  setClickedRenderPentPrez(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
clickedRenderKv220Prez === false &&
clickedRenderKv220Plan === false &&

clickedRenderPentInter === false &&
clickedRenderPentPlanMB15 === false &&
clickedRenderProjectMB15Prez === false &&
clickedRenderProjectMB15Fasad === false &&
clickedRenderProjectMB15Mop === false &&
clickedRenderProjectMB15Parking === false
){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}



const handleChangeChekcedRenderProjectMB15Prez = (e) => {
  setClickedRenderProjectMB15Prez(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&
    clickedRenderPentPlanMB15 === false &&

    clickedRenderProjectMB15Fasad === false &&
    clickedRenderProjectMB15Mop === false &&
    clickedRenderProjectMB15Parking === false
){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}

const handleChangeChekcedRenderProjectMB15Fasad = (e) => {
  setClickedRenderProjectMB15Fasad(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&
    clickedRenderPentPlanMB15 === false &&
    clickedRenderProjectMB15Prez === false &&
    
    clickedRenderProjectMB15Mop === false &&
    clickedRenderProjectMB15Parking === false
){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}

const handleChangeChekcedRenderProjectMB15Mop = (e) => {
  setClickedRenderProjectMB15Mop(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&
    clickedRenderPentPlanMB15 === false &&
    clickedRenderProjectMB15Prez === false &&
    clickedRenderProjectMB15Fasad === false &&
    
    clickedRenderProjectMB15Parking === false
    ){
      tg.MainButton.hide()
  }
  else{
      tg.MainButton.show()
  }
}
const handleChangeChekcedRenderProjectMB15Parking = (e) => {
  setClickedRenderProjectMB15Parking(e.target.checked)
  
  if(
    e.target.checked == false &&
    clickedRenderKv220Inter === false &&
    clickedRenderKv220Prez === false &&
    clickedRenderKv220Plan === false &&
    clickedRenderPentPrezMB15 === false &&
    clickedRenderPentInter === false &&
    clickedRenderPentPlanMB15 === false &&
    clickedRenderProjectMB15Prez === false &&
    clickedRenderProjectMB15Fasad === false &&
    clickedRenderProjectMB15Mop === false 
   
    ){
          tg.MainButton.hide()
      }
      else{
          tg.MainButton.show()
      }
    }



const sendDataToTelegram = () => {
  tg.sendData(`{"clickedRenderKv220Inter": "${clickedRenderKv220Inter}",
  "clickedRenderKv220Prez": "${clickedRenderKv220Prez}",
  "clickedRenderKv220Plan": "${clickedRenderKv220Plan}",
  "clickedRenderPentPrezMB15": "${clickedRenderPentPrezMB15}",
  "clickedRenderPentInter": "${clickedRenderPentInter}", 
  "clickedRenderPentPlanMB15": "${clickedRenderPentPlanMB15}", 
  "clickedRenderProjectMB15Prez": "${clickedRenderProjectMB15Prez}", 
  "clickedRenderProjectMB15Fasad": "${clickedRenderProjectMB15Fasad}", 
  "clickedRenderProjectMB15Mop": "${clickedRenderProjectMB15Mop}", 
  "clickedRenderProjectMB15Parking": "${clickedRenderProjectMB15Parking}", 
 }`);
}


React.useEffect(() => {
  tg.onEvent('mainButtonClicked', sendDataToTelegram)
  return () => {
    tg.offEvent('mainButtonClicked', sendDataToTelegram)
  }
}, [sendDataToTelegram])


useEffect(() => {
  window.scrollTo(0, 0)
  tg.MainButton.hide()
}, [])

  return (<>
    <Grid  container style={{justifyContent:"center"}}>
      <Box sx={{pt:3}}>
      <img src='../../img/MB15.png' alt="Logo" width="280" />
      </Box>
      <Box sx={{pt:3}} >
             <img  src='../../img/mb15.jpg' width="380" />
      </Box>
      </Grid>
      
        <Grid container  direction="column" alignItems="left" justify="left">
        <Box sx={{p:3}} >
        <Typography sx={{mb:1}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Информация о проекте</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox color="default" />} label="Презентация" checked={clickedRenderProjectMB15Prez} onChange={handleChangeChekcedRenderProjectMB15Prez}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Фасад" checked={clickedRenderProjectMB15Fasad} onChange={handleChangeChekcedRenderProjectMB15Fasad}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Места общественного пользования" checked={clickedRenderProjectMB15Mop} onChange={handleChangeChekcedRenderProjectMB15Mop}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Паркинг" checked={clickedRenderProjectMB15Parking} onChange={handleChangeChekcedRenderProjectMB15Parking}/>
        </FormGroup>
        {/* <Typography sx={{mb:1, pt:3,}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Квартира - 220 кв.м</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox color="default" />} label="Презентация" checked={clickedRenderKv220Prez} onChange={handleChangeChekcedRenderKv220Prez}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Интерьеры" checked={clickedRenderKv220Inter} onChange={handleChangeChekcedRenderKv220Inter}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Планировочное решение" checked={clickedRenderKv220Plan} onChange={handleChangeChekcedRenderKv220Plan} />
        </FormGroup> */}
        {/* <Typography sx={{mb:1, pt:3,}} style={{color:"rgb(100, 100, 100)", fontSize: '20px'}}>Пентхаус - 434 кв.м</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox color="default" />} label="Презентация" checked={clickedRenderPentPrezMB15} onChange={handleChangeChekcedRenderPentPrez}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Террасы" checked={clickedRenderPentInter} onChange={handleChangeChekcedRenderPentInter}/>
            <FormControlLabel control={<Checkbox color="default" />} label="Планировочное решение" checked={clickedRenderPentPlanMB15} onChange={handleChangeChekcedRenderPentPlan}/>
            
        </FormGroup> */}
        </Box>
        </Grid>
      
    
    </>
  );
};
 
export default MalayaBronnaya;