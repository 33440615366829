import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import { blueGrey } from '@mui/material/colors';

const Home = () => {
  // const [clickedReg, setClickedReg] = React.useState(false);
  // const [clickedRegBr, setClickedRegBr] = React.useState(false);

  
  let tg = window.Telegram.WebApp;

    
  const sendDataToTelegram = (e) => {
    if (e.target.name === 'broker'){
      tg.sendData(`{"clickedRegBr": "clickedRegBr",}`);
    }
    else{
      tg.sendData(`{"clickedReg": "clickedReg",}`);
    }
   
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    tg.MainButton.hide()
  }, [])

    return (
      <Box sx={{p:3}}>
      <Grid container  direction="column" alignItems="center" style={{justifyContent:"center"}}>
        
          <h3 sx={{pb:3}}>Приветствуем вас в чат-боте R4S!</h3>
          <p>Для комфортного взаимодействия с агентствами недвижимости был создан чат бот R4S broker. Здесь вы всегда сможете найти полную и актуальную информацию о жилых проектах.</p>
          <p>R4S  — девелопер жилой и коммерческой недвижимости класса de luxe в самых знаковых локациях Москвы. Нас отличают особый подход к каждому проекту и исключительный сервис. В сотрудничестве с международными архитекторами и дизайнерами мы разрабатываем уникальные концепции для каждого проекта. В готовых решениях сочетаются эстетика, комфорт и функциональность. Каждый проект R4S — со своей особенной атмосферой и безупречным воплощением. Эксклюзивные интерьеры сочетаются с современными технологиями. В 2021 году компания реализовала клубный дом «Малая Бронная 15». В ближайшее время R4S  планирует ввод ещё нескольких жилых проектов класса de luxe в самом сердце Москвы.</p>
          <h4 sx={{pt:3}}>Регламенты взаимодействия c брокерами и агентствами недвижимости</h4>
          <Button sx={{ color: blueGrey[500],
            borderColor: blueGrey[500],
            '&:hover': { color: blueGrey[500], borderColor: blueGrey[500], }}} style = {{width: 350}} size="small" variant="outlined" name='broker' onClick={sendDataToTelegram}>скачать</Button>
          <h4 sx={{pt:3}}>Регламент взаимодействия с агентствами по рекламе</h4>
          <Button sx={{ color: blueGrey[500],
            borderColor: blueGrey[500],
            '&:hover': { color: blueGrey[500], borderColor: blueGrey[500], }}} style = {{width: 350}} size="small" variant="outlined" name='reklama' onClick={sendDataToTelegram}>скачать</Button>
        </Grid>
        </Box>
  );
};
 
export default Home;