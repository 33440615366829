import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import { blueGrey } from '@mui/material/colors';

const Confidec = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <Box sx={{p:3}}>
      <Grid container  direction="column" alignItems="center" style={{justifyContent:"center"}}>
        
          <p >ОБЩИЕ ПОЛОЖЕНИЯ</p>
          <p>Настоящий документ определяет политику ООО «ЛидЭстейт» (ИНН 7703411021) (далее – «Компания») в отношении обработки персональных данных и излагает систему основных принципов, применяемых в отношении обработки и обеспечения безопасности персональных данных в Компании. 
                Действие настоящей Политики распространяется на все операции, совершаемые в Компании с персональными данными с использованием средств автоматизации или без их использования.
                Настоящая Политика обязательна для ознакомления и исполнения всеми лицами, допущенными к обработке персональных данных в Компании, и лицами, участвующими в организации процессов обработки и обеспечения безопасности персональных данных в Компании.
                Настоящая Политика составлена в соответствии с Конвенцией Совета Европы №108 о защите личности в связи с автоматической обработкой персональных данных и Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г.
                Настоящая Политика подлежит актуализации в случае изменения законодательства РФ о персональных данных.
                </p>

            <p >ВВЕДЕНИЕ</p>
            <p>Компания является оператором персональных данных.
                Важным условием реализации целей деятельности Компании является обеспечение защиты прав и свобод субъекта персональных данных при обработке его персональных данных.
                В Компании разработаны и введены в действие документы, устанавливающие порядок обработки и обеспечения безопасности персональных данных, которые обеспечивают соответствие требованиям Федерального закона РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятых в соответствии с ним нормативных правовых актов.
                </p>
            
            <p >ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ В КОМПАНИИ</p>
          <p>Компания, являясь оператором, осуществляет обработку следующих персональных данных:
                соискателей на замещение вакантных должностей – в составе и в сроки, необходимые для принятия Компанией решения о приеме либо отказе в приеме на работу, с согласия субъектов персональных данных, а также для формирования кадрового резерва с согласия субъектов персональных данных;
                работников, состоящих или состоявших в трудовых отношениях с Компанией – в составе и в сроки, необходимые для достижения целей, предусмотренных законодательством РФ, осуществления и выполнения возложенных законодательством РФ на Компанию функций, полномочий и обязанностей, для формирования кадрового резерва с согласия субъектов персональных данных, а также для заключения и исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, в том числе в целях предоставления страхования с согласия субъектов персональных данных;
                родственников работников Компании – в составе и в сроки, необходимые для осуществления и выполнения возложенных законодательством РФ на Компанию функций, полномочий и обязанностей, осуществления прав и законных интересов Компании, а также для заключения и исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, в том числе, в целях предоставления страхования с согласия субъектов персональных данных;
                представителей поставщиков Компании – в составе и в сроки, необходимые для осуществления взаимодействия с поставщиками с согласия субъектов персональных данных;
                лиц, связанных с несчастными случаями – в составе и в сроки, необходимые для достижения целей, предусмотренных законодательством РФ, осуществления и выполнения возложенных законодательством РФ на Компанию функций, полномочий и обязанностей;
                лиц, получающих доход, но не состоящих в трудовых отношениях с Компанией, в составе и в сроки, необходимые для достижения целей, предусмотренных законодательством РФ, осуществления и выполнения возложенных законодательством РФ на Компанию функций, полномочий и обязанностей;
                потенциальных и существующих клиентов – в составе и в сроки, необходимые для осуществления взаимодействия с потенциальными и существующими клиентами, в том числе, полученных через сайт, принадлежащий Оператору, с согласия субъектов персональных данных;
                представителей потенциальных и существующих клиентов – в составе и в сроки, необходимые для осуществления взаимодействия с потенциальными и существующими клиентами, с согласия субъектов персональных данных;
                представителей партнеров – в составе и в сроки, необходимые для осуществления взаимодействия с партнерами, с согласия субъектов персональных данных.
                Сроки обработки персональных данных определены с учетом:
                установленных целей обработки персональных данных;
                сроков действия договоров с субъектами персональных данных и согласий субъектов персональных данных на обработку их персональных данных;
                сроков, определенных Приказом Минкультуры РФ от 25 августа 2010 г. № 558 «Об утверждении «Перечня типовых управленческих архивных документов, образующихся в процессе деятельности государственных органов, органов местного самоуправления и организаций, с указанием сроков хранения».
                Компания осуществляет обработку персональных данных на законной и справедливой основе.
                При обработке персональных данных обеспечиваются их точность, достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных.
                Компания не раскрывает третьим лицам и не распространяет персональные данные без согласия субъекта персональных данных (если иное не предусмотрено федеральным законом РФ).
                Компания осуществляет обработку специальных категорий персональных данных лиц, связанных с несчастными случаями, работников (данные о состоянии здоровья в рамках трудовых отношений). При этом Компания выполняет требования к осуществлению обработки специальных категорий персональных данных, предусмотренные Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и Трудовым кодексом РФ.
                Компания не осуществляет обработку биометрических персональных данных.
                Компания не осуществляет трансграничную передачу персональных данных.
                В Компании не осуществляется принятие решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы, на основании исключительно автоматизированной обработки персональных данных.
                Компания может поручить обработку персональных данных другому лицу. При этом Компания выполняет требования к поручению обработки персональных данных, предусмотренные Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г.
                Компания осуществляет обработку персональных данных с использованием средств автоматизации и без их использования. При этом Компания выполняет требования к автоматизированной и неавтоматизированной обработке персональных данных, предусмотренные Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятыми в соответствии с ним нормативными правовыми актами.
                </p>

            <p>ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОБРАБАТЫВАЕМЫХ В КОМПАНИИ</p>
            <p>Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных данных. Для получения указанной информации субъект персональных данных может отправить письменный запрос (запрос может быть также направлен в форме электронного документа и подписан электронной подписью) на адрес: 125009, г. Москва, вн.тер.г. Муниципальный округ Пресненский, ул. Большая Никитская, дом 16, этаж 3, ком. 12, в порядке, установленном ст.14 Федерального закона РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. </p>
            
            <p>ИСПОЛНЕНИЕ ОБЯЗАННОСТЕЙ ОПЕРАТОРА КОМПАНИЕЙ</p>
            <p>
            Компания получает персональные данные от субъектов персональных данных и от третьих лиц (лиц, не являющихся субъектами персональных данных). При этом Компания выполняет обязанности, предусмотренные Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и Трудового кодекса РФ при сборе персональных данных.
                Компания прекращает обработку персональных данных в следующих случаях:
                при наступлении условий прекращения обработки персональных данных или по истечении установленных сроков;
                по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;
                по требованию субъекта персональных данных, если обрабатываемые в Компании персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
                в случае выявления неправомерной обработки персональных данных, если обеспечить правомерность обработки персональных данных невозможно;
                в случае отзыва субъектом персональных данных согласия на обработку его персональных данных или истечения срока действия такого согласия (если персональные данные обрабатываются Компанией исключительно на основании согласия субъекта персональных данных);
                в случае ликвидации Компании.
                Компанией для обеспечения выполнения обязанностей, предусмотренных Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятыми в соответствии с ним нормативными правовыми актами, приняты следующие меры:
                назначено лицо, ответственное за организацию обработки персональных данных;
                изданы локальные акты по вопросам обработки и обеспечения безопасности персональных данных, а также локальные акты, устанавливающие процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений:
                Положение об обработке персональных данных;
                настоящая Политика;
                другие локальные акты по вопросам обработки и обеспечения безопасности персональных данных;
                применены правовые, организационные и технические меры по обеспечению безопасности персональных данных;
                осуществляется внутренний контроль соответствия обработки персональных данных требованиям Федерального закона РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятых в соответствии с ним нормативных правовых актов, настоящей Политики, локальных актов Компании;
                проведена оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения требований федерального законодательства о персональных данных, произведено соотношение указанного вреда и принимаемых Компанией мер, направленных на обеспечение выполнения обязанностей, предусмотренных требованиями Федерального закона РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятых в соответствии с ним нормативных правовых актов;
                работники Компании, непосредственно осуществляющие обработку персональных данных, ознакомлены с положениями Федерального закона РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г. и принятых в соответствии с ним нормативных правовых актов, настоящей Политики и локальных актов Компании по вопросам обработки персональных данных.
                В Компании реализуются следующие требования к защите персональных данных:
                организован режим обеспечения безопасности помещений, в которых размещены информационные системы, препятствующий возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;
                реализовано обеспечение сохранности носителей персональных данных;
                руководителем Компании утвержден документ, определяющий перечень лиц, доступ которых к персональным данным, обрабатываемым в информационной системе, необходим для выполнения ими служебных (трудовых) обязанностей;
                используются средства защиты информации, прошедшие процедуру оценки соответствия требованиям законодательства РФ в области обеспечения безопасности информации;
                реализованы требования, установленные Постановлением Правительства РФ от 15 сентября 2008 г. №687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации».
            </p>

            <p>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
            <p>
            Настоящая Политика является локальным нормативным актом Компании. Настоящая Политика является общедоступной. Общедоступность настоящей Политики обеспечивается публикацией на Сайте Компании.
Настоящая Политика может быть пересмотрена в любом из следующих случаев:
­ при изменении законодательства Российской Федерации в области обработки и защиты персональных данных;
­ в случаях получения предписаний от компетентных государственных органов на устранение несоответствий, затрагивающих область действия Политики;
- по решению руководства Компании;
- при изменении целей и сроков обработки персональных данных;
- при изменении организационной структуры, структуры информационных и/или телекоммуникационных систем (или введении новых);
- при применении новых технологий обработки и защиты персональных данных (в т. ч. передачи, хранения);
- при появлении необходимости в изменении процесса обработки персональных данных, связанной с деятельностью Компании.
В случае неисполнения положений настоящей Политики Компания и ее работники несут ответственность в соответствии с действующим законодательством Российской Федерации.
Контроль исполнения требований настоящей Политики осуществляется лицами, ответственными за организацию обработки персональных данных Компании, а также за безопасность персональных данных.

            </p>



        </Grid>
        </Box>
  );
};
 
export default Confidec;