import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import style from './object.css';
import { blueGrey } from '@mui/material/colors';
import Grid from '@material-ui/core/Grid';

const Object = () => {
  const [clicked, setClicked] = React.useState(true);
  const color = blueGrey[900];


  let tg = window.Telegram.WebApp;
  tg.MainButton.hide()
 

  useEffect(() => {
    window.scrollTo(0, 0)
    
  }, [])

  return (
<Grid container  direction="column" alignItems="center" style={{justifyContent:"center"}}>
  <Box sx={{p:3}}  alignItems="center">
      <Card sx={{ maxWidth: 345, mb:3, pb:1  }}>
        <CardMedia
          component="img"
          height="320"
          image="../../img/1_1_1(1).jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{pb:1}}>
          <img src='../../img/MB15.png' alt="Logo" width="280" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Клубный дом «Малая Бронная 15» расположен в одной из самых востребованных локаций исторической Москвы — на Патриарших прудах. Дом стал воплощением современного прочтения классики. Роскошная архитектура здания в стиле неоклассицизм изящно вписана в исторический контекст района.
          </Typography>
        </CardContent>
        <CardActions sx={{pl:2}}>
          <NavLink to="/malayabronnaya" style={{ textDecoration: 'none',}}><Button size="small" style = {{width: 313}} sx={{ color: blueGrey[500],
            borderColor: blueGrey[500],
            '&:hover': { color: blueGrey[500], borderColor: blueGrey[500], }}} variant="outlined">Информационые материалы</Button></NavLink>
        </CardActions>
      </Card>

      <Card sx={{ maxWidth: 345, mb:3, pb:1 }}>
        <CardMedia
          component="img"
          height="320"
          image="../../img/DSC09549.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{pb:1}}>
          <img src='../../img/Logo black.png' alt="Logo" width="150" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
          The Patricks — исторический дом для современной жизни на Патриарших прудах. В новом проекте, не имеющем аналогов в Москве, сбалансированы история и современный дизайн. Здесь ценят эстетику и приватность, индивидуальность и комфорт.
          </Typography>
        </CardContent>
        <CardActions sx={{pl:2}}>
          <NavLink to="/thepatricks" style={{ textDecoration: 'none' }}><Button size="small" style = {{width: 313}} sx={{ color: blueGrey[500],
            borderColor: blueGrey[500],
            '&:hover': { color: blueGrey[500], borderColor: blueGrey[500] },  }} variant="outlined">Информационые материалы</Button></NavLink>
        </CardActions>
      </Card>
      </Box>

    </Grid>
    // <Box sx={{ width: '100%' }} >
    //   <Stack sx={{p:3}} alignItems="center">
    //     <img src='../../img/MB15.png' alt="Logo" width="300"/>
    //     <p>КЛУБНЫЙ ДОМ НА ПАТРИАРШИХ</p>

        
    //   <ImageList
    //   sx={{ width: 400, height: 450 }}
    //   variant="quilted"
    //   cols={4}
    //   rowHeight={121}
    // >
    //   {itemData.map((item) => (
    //     <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
    //       <img
    //         {...srcset(item.img, 121, item.rows, item.cols)}
    //         alt={item.title}
    //         loading="lazy"
    //       />
    //     </ImageListItem>
    //   ))}
    // </ImageList>


    //   </Stack>

    //   </Box>
  );
};
 

const itemData = [
  {
    img: '../../img/1_1_1(1).jpg',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: '../../img/3H9A3119.jpg',
    title: 'Burger',
  },
  {
    img: '../../img/chen.jpg',
    title: 'Camera',
  },
  {
    img: '../../img/салон нов. вид 2.jpg',
    title: 'Coffee',
    cols: 2,
  },
  {
    img: '../../img/Gost.jpg',
    title: 'Hats',
    cols: 2,
  },
  {
    img: '../../img/06_1.jpg',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: '../../img/07.jpg',
    title: 'Basketball',
  },
  {
    img: '../../img/DSC08283.jpg',
    title: 'Fern',
  },
  {
    img: '../../img/L1010694.jpg',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: '../../img/L1010733.jpg',
    title: 'Tomato basil',
  },
  {
    img: '../../img/L1010734.jpg',
    title: 'Sea star',
  },
  {
    img: '../../img/L1010740.jpg',
    title: 'Bike',
    cols: 2,
  },
];


export default Object;