import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import { blueGrey } from '@mui/material/colors';

const PersData = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <Box sx={{p:3}}>
      <Grid container  direction="column" alignItems="center" style={{justifyContent:"center"}}>
        
          <p>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
          <p>Пользователь, ставя «галочку» в поле «Даю согласие на обработку персональных данных», обязуется принять условия настоящего согласия на обработку персональных данных (далее «Согласие») и дает свое согласие на обработку своих персональных данных ООО «ЛидЭстейт» (ИНН 7703411021) (далее «Оператор»/ООО «ЛидЭстейт»), расположенного по адресу (юридический адрес): 125009, г. Москва, вн.тер.г. Муниципальный округ Пресненский, ул. Большая Никитская, дом 16, этаж 3, ком. 12, и которому принадлежит бот в Telegram r4s-dev-test.
                Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.
                Перечень персональных данных, на обработку которых дается Согласие:
                Имя;
                Контактный телефон.
                Цель обработки персональных данных: получение информации о пользователях бота Telegram r4s-dev-test в целях дальнейшего сотрудничества с пользователем и исполнения договорных обязательств перед пользователями, клиентами, контрагентами и иными субъектами персональных данных. 
                Перечень действий с персональными данными, на совершение которых дается Согласие (в соответствии с п. 3 ст. 3 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»): 
                в ходе обработки персональных данных будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); использование; обезличивание; удаление; уничтожение. 
                Согласие дается, в том числе, на возможные информационные (рекламные) оповещения (включая осуществление информационных рассылок, рассылок о маркетинговых мероприятиях, специальных предложениях и акциях посредством SMS и e-mail).
                Передача персональных данных третьим лицам осуществляется на основании законодательства Российской Федерации, договора с участием субъекта персональных данных или согласия субъекта персональных данных.
                Данное Согласие может быть отозвано по письменному заявлению субъекта персональных данных, направленному ООО «ЛидЭстейт» или его представителю по юридическому адресу, указанному в настоящем Согласии.
                Пользователь подтверждает, что, предоставляя настоящее Согласие, действует по собственной воле и в своих интересах. 
                Данное Согласие действует до достижения целей обработки персональных данных или в течение сроков хранения информации, установленных РФ.
                </p>
         
          
                
        </Grid>
        </Box>
  );
};
 
export default PersData;