import * as React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import DomainIcon from '@mui/icons-material/Domain';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { red } from '@mui/material/colors';
import Grid from '@material-ui/core/Grid';
import MessageIcon from '@mui/icons-material/Message';

const color = red[500];

export default function Navbar() {
  const [value, setValue] = React.useState('home');
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    navigate(newValue);
    setValue(newValue);
  };


  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    if (newValue == 0){
    setValueTab("/");
    navigate("/");
    setValue("/");}
    else {
      setValueTab(newValue);
      navigate(newValue);
      setValue(newValue);
    }
  };

  return (
    
    
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>  
      <Grid container   alignItems="center" style={{justifyContent:"center"}}>
          <Tabs value={valueTab} onChange={handleChangeTab}  TabIndicatorProps={{sx:{backgroundColor:"#232323", height:"3px"}}}
            sx={{ 
              // задаем цвет со старта
              "& button": {color:"#232323"},
              "& button.Mui-selected": {color:"#000000"},
              // Задаем цвет активной вкладке
              "& button:focus": {color:"#000000"},
              
            }} aria-label="nav icon label tabs example">
            <Tab icon={<AddToHomeScreenIcon />} sx={{pl:1, pr:2, fontSize: "10px"}} label="Главная" value={0}/>
            <Tab icon={<MessageIcon />} sx={{pl:2, pr:2, fontSize: "10px"}} label="Брокер тур" value="broker" />
            <Tab icon={<GroupIcon />} sx={{pl:2, pr:2, fontSize: "10px"}} label="Проверить" value="client" />
            <Tab icon={<DomainIcon />} sx={{pl:2, pr:1, fontSize: "10px"}} label="Объекты" value="object"/>
          </Tabs>
          </Grid>
      </Box>
    
    
  );
}