import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import Client from './pages/client';
import Object from './pages/object';
import MalayaBronnaya from './pages/malayaBronnaya';
import ThePatricks from './pages/thePatricks';
import PersData from './pages/persData';
import Confidec from './pages/confidec';
import Broker from './pages/broker';

function App() {
return (
    <Router>
    <Navbar />
    <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/client' element={<Client/>} />
        <Route path='/object' element={<Object/>} />
        <Route path='/broker' element={<Broker/>} />
        <Route path='/malayabronnaya' element={<MalayaBronnaya/>} />
        <Route path='/thepatricks' element={<ThePatricks/>} />
        <Route path='/persdata' element={<PersData/>} />
        <Route path='/confidec' element={<Confidec/>} />
        
    </Routes>
    </Router>
);
}
 
export default App;
